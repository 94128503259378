import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const MenuWrapper = styled.ul`
  display: flex;
  font-size: 14px;

  li {
    display: block;
  }
`

export const Link = styled(NavLink)`
  text-decoration: none;
  color: #fff;
  padding: 10px 15px;
  font-size: 18px;

  &:hover {
    color: #fff;
    text-decoration: none;
    border-bottom: 2px solid #43cda2;
  }

  &.active {
    border-bottom: 2px solid #43cda2;
  }
`
