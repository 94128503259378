import styled from 'styled-components'
import { FooterList } from '../Footer/styled'

export const ContactDetailsTitle = styled.span`
  font-size: 35px;
  margin-bottom: 30px;
  color: #333;
`

export const StyledContactDetails = styled(FooterList)`
  margin-bottom: 20px;
  li {
    color: #333;
    font-weight: lighter;
    line-height: 1.5;
  }
`
