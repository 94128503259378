import * as React from 'react'

import { Content, FullWidthContent, ContentLeft, ContentRight } from '../../components/Content/Content'
import { GradientTitle } from '../../components/GradientTitle/GradientTitle'

import Granulaat from './../../assets/img/Granulaat.jpg'
import IndustrieBaal from './../../assets/img/Industriebaal.jpg'

class About extends React.PureComponent<any, any> {
  public render() {
    return (
      <>
        <FullWidthContent backgroundColor="#fff">
          <Content>
            <ContentLeft>
              <GradientTitle>About us</GradientTitle>
            </ContentLeft>
          </Content>
          <Content between>
            <ContentLeft column>
              <p>Ecocircle is established in 2014.</p>
              <p>
                We are specialized in Bulk liners, a post-production waste. Dry-bulk liners and Liquid Liners in the
                non- hazardous sector. These liners can be used in sea containers or in liquid tanks. Our production
                side is in the Rotterdam harbour. At the position where disposing of liners begins.
              </p>
              <p>
                Ecocircle is working with partners as Eceplast, a dry bulk liner manufacturer, and Trust, a liquid bulk
                manufacturer, who deeply understand the urge of recycling their products. With joint forces we work
                towards a situation that all liners will be disposed in a Eco-friendly way.
              </p>
              <p>
                Ecocircle is also working with partners to find technical solutions to recycle the liners. APV is a
                French partner who toke up the glove and found a way to recycle liners, which can be a very dificult
                product to recycle it, into a high quality granule.
              </p>
            </ContentLeft>
            <ContentRight column end>
              <img src={Granulaat} />
              <img src={IndustrieBaal} />
            </ContentRight>
          </Content>
        </FullWidthContent>
      </>
    )
  }
}

export default About
