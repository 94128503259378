import React from 'react'
import { StyledContactDetails, ContactDetailsTitle } from './styled'

const ContactDetails = () => {
  return (
    <>
      <ContactDetailsTitle>Details</ContactDetailsTitle>
      <StyledContactDetails>
        <li style={{ fontWeight: 'bold' }}>Office</li>
        <li>EcoCircle</li>
        <li>Korianderveld 5</li>
        <li>3124 CC</li>
        <li>Schiedam</li>
        <li>+31 655 111 115</li>
        <li>BTW: NL860326871B01</li>
        <li>KvK: 75570734</li>
        <li>VIHB: 533316VIHB</li>
      </StyledContactDetails>
      <StyledContactDetails>
        <li style={{ fontWeight: 'bold' }}>Production site</li>
        <li>At the side of Broekman Distriport BV</li>
        <li>Port Number 5116 C</li>
        <li>Mannheimweg 3</li>
        <li>3197 LR</li>
        <li>Rotterdam Botlek</li>
        <li>The Netherlands</li>
      </StyledContactDetails>
    </>
  )
}

export default ContactDetails
