import styled from 'styled-components'
import { FooterList } from '../Footer/styled'

export const FormItem = styled.div`
  flex: 1;
  padding: 0 10px;

  label {
    display: block;
    margin-bottom: 2px;
    font-size: 12px;
    font-weight: normal;
  }

  input {
    width: 100%;
    padding: 10px 5px;
    outline: none;
    font-size: 14px;
  }

  textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
  }

  button {
    border: none;
    outline: none;
    font-size: 14px;
    width: 100%;
    color: #fff;
    background-color: #40c5a1;
    border-radius: 3px;
    padding: 15px 0;
    &:hover {
      cursor: pointer;
    }
  }
`

export const FormGroup = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 15px;

  ${FormItem}:first-child {
    padding-left: 0;
  }
  ${FormItem}:last-child {
    padding-right: 0;
  }
`
