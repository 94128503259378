import 'core-js/stable'

import * as React from 'react'
import * as ReactDOM from 'react-dom'

import 'typeface-roboto'
import './index.scss'
import App from './containers/App/App'

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement)
