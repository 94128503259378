import styled from 'styled-components'

export const GradientTitle = styled.span`
  font-size: 45px;
  font-weight: bold;
  color: #fff;
  padding-bottom: 10px;
  background: linear-gradient(45deg, #40c5a1, #2a8b9f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
