import * as React from 'react'
import { MenuWrapper, Link } from './styled'

export const Menu = () => {
  return (
    <div>
      <MenuWrapper>
        <li>
          <Link activeClassName="active" to="/home">
            Home
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/about">
            About
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/partners">
            Partners
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/contact">
            Contact
          </Link>
        </li>
      </MenuWrapper>
    </div>
  )
}
