import * as React from 'react'
import { FooterContainer, SocialMedia, FooterTitle, FooterList } from './styled'
import { Icon } from '../Icon/Icon'
import { Content, ContentLeft, FullWidthContent } from '../Content/Content'
import { Link } from 'react-router-dom'

export const Footer = (): React.ReactElement => {
  return (
    <FooterContainer backgroundColor="#40c5a1">
      <Content>
        <ContentLeft column>
          <FooterTitle>Links</FooterTitle>
          <FooterList>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/partners">Partners</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </FooterList>
        </ContentLeft>
        <ContentLeft column>
          <FooterTitle>Partners</FooterTitle>
          <FooterList>
            <li>
              <a href="https://www.eceplast.com/" target="_NEW">
                Eceplast
              </a>
            </li>
            <li>
              <a href="https://www.trustflexitanks.com/" target="_NEW">
                Trust
              </a>
            </li>
          </FooterList>
        </ContentLeft>
        <ContentLeft center column>
          <FooterTitle>Details</FooterTitle>
          <FooterList>
            <li>Ecocircle</li>
            <li>Korianderveld 5</li>
            <li>3124 CC</li>
            <li>Schiedam</li>
            <li>+31 655 111 115</li>
            <li>KvK: 75570734</li>
            <li>BTW: NL860326871B01</li>
            <li>VIHB: 533316VIHB</li>
          </FooterList>
        </ContentLeft>
      </Content>
    </FooterContainer>
  )
}
