import styled from 'styled-components'
import { FullWidthContent } from '../Content/Content'

export const FooterContainer = styled(FullWidthContent)`
  margin-top: auto;
`

export const FooterTitle = styled.span`
  font-size: 30px;
  color: #fff;
  display: block;
`

export const FooterList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    font-size: 14px;
    color: #fff;
  }

  li a {
    color: #fff;
    text-decoration: none;
  }
`

export const SocialMedia = styled.div`
  display: block;
  padding-bottom: 10px;
  .icon {
    margin: 0 5px;
  }
`
