import styled from 'styled-components'

export const BannerContainer = styled.div`
  background-color: #40c5a1;
  padding: 20px 0px;
`

export const BannerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const BannerTitle = styled.span`
  font-size: 45px;
  color: #fff;
`

export const BannerSponsers = styled.div`
  img {
    height: 100px;
    margin: 0 40px;
  }

  img:last-child {
    margin-right: 0;
  }
`
