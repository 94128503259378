import styled from 'styled-components'

export const FullWidthContainer = styled.div`
  width: 100%;
  ${(props: any) => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  padding: 50px 0;
`

export const ContentContainer = styled.div`
  width: 1200px;
  margin: 0 auto 0 auto;
  display: flex;
  ${(props: any) => props.between && `justify-content: space-between;`}
  ${(props: any) => props.vCenter && `align-items: center;`}
`

export const ContentSide = styled.div`
  display: flex;
  flex: 1;
  margin: 0 20px;
  ${(props: any) => props.column && `flex-direction: column;`}
  ${(props: any) => props.end && `justify-content: flex-end;`}
  ${(props: any) => props.center && `justify-content: center;`}

  img {
    border-radius: 10px;
    margin-bottom: 40px;
  }
`
