import * as React from 'react'
import { Menu } from '../Menu/Menu'
import logoSingle from './../../assets/img/ecocirlcle-logo-single.png'
import { HeaderWrapper } from './styled'

const Logo = () => {
  return (
    <a href="#" className="top-logo">
      <img src={logoSingle} height="75" />
    </a>
  )
}

export const Header = () => {
  return (
    <HeaderWrapper>
      <Logo />
      <Menu />
    </HeaderWrapper>
  )
}
