import * as React from 'react'
import { Hero } from '../../components/Hero/Hero'

import { Content, FullWidthContent, ContentLeft, ContentRight } from '../../components/Content/Content'
import { BannerTitle, BannerSponsers } from '../../components/PartnerBanner/styled'
import { GradientTitle } from '../../components/GradientTitle/GradientTitle'

import sponser1 from './../../assets/img/eceplast-sponser.png'
import sponser2 from './../../assets/img/trust-sponser.png'
import shred from './../../assets/img/shred.png'
import containerEcocircle from './../../assets/img/container ecocircle.jpg'

class Home extends React.PureComponent<any, any> {
  public render() {
    return (
      <div>
        <Hero />
        <FullWidthContent backgroundColor="#40c5a1">
          <Content between>
            <ContentLeft>
              <BannerTitle>Partners</BannerTitle>
            </ContentLeft>
            <ContentRight end>
              <BannerSponsers>
                <img src={sponser1} />
                <img src={sponser2} />
              </BannerSponsers>
            </ContentRight>
          </Content>
        </FullWidthContent>

        <FullWidthContent backgroundColor="#eee">
          <Content>
            <ContentLeft>
              <GradientTitle>This is us</GradientTitle>
            </ContentLeft>
          </Content>
          <Content between>
            <ContentLeft column>
              <p>EcoCircle is a collector of Liner plastics. This can be dry-bulk liners and liquid liners.</p>
              <p>
                Plastic recycling is the return of used plastics in the form of new products and applications. Most
                types of plastics are suitable for recycling after use. Usually it is reduced to raw material for the
                production of new products. This means that the plastic used does not become waste and less use is made
                of new raw materials. This is why we contribute to a better environment and as far as we are concerned,
                the circle is only complete and we want to keep this circle running.
              </p>
            </ContentLeft>
            <ContentRight end>
              <img src={shred} height="300" />
            </ContentRight>
          </Content>
        </FullWidthContent>

        <FullWidthContent backgroundColor="#fff">
          <Content>
            <ContentLeft />
            <ContentRight>
              <GradientTitle>What we do</GradientTitle>
            </ContentRight>
          </Content>
          <Content between vCenter>
            <ContentLeft start>
              <img src={containerEcocircle} height="300" />
            </ContentLeft>
            <ContentRight column>
              <p>EcoCircle is a collector of Liner plastics. This can be dry-bulk liners and liquid liners.</p>
              <p>
                Plastic recycling is the return of used plastics in the form of new products and applications. Most
                types of plastics are suitable for recycling after use. Usually it is reduced to raw material for the
                production of new products. This means that the plastic used does not become waste and less use is made
                of new raw materials. This is why we contribute to a better environment and as far as we are concerned,
                the circle is only complete and we want to keep this circle running.
              </p>
            </ContentRight>
          </Content>
        </FullWidthContent>
      </div>
    )
  }
}

export default Home
