import React from 'react'
import { FormGroup, FormItem } from './styled'

const ContactForm = () => {
  const [firstName, setFirstName] = React.useState()
  const [lastName, setLastName] = React.useState()
  const [email, setEmail] = React.useState()
  const [phone, setPhone] = React.useState()
  const [message, setMessage] = React.useState()

  function handleFormSubmit(e) {
    e.preventDefault()
  }

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <FormGroup>
          <FormItem>
            <label>Firstname</label>
            <input name="firstName" value={firstName} onChange={(e) => setFirstName(e.currentTarget.value)} />
          </FormItem>
          <FormItem>
            <label>Lastname</label>
            <input name="lastName" value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} />
          </FormItem>
        </FormGroup>

        <FormGroup>
          <FormItem>
            <label>E-mail</label>
            <input name="email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
          </FormItem>
          <FormItem>
            <label>Phone</label>
            <input name="phone" value={phone} onChange={(e) => setPhone(e.currentTarget.value)} />
          </FormItem>
        </FormGroup>

        <FormGroup>
          <FormItem>
            <label>Message</label>
            <textarea name="message" value={message} onChange={(e) => setMessage(e.currentTarget.value)} />
          </FormItem>
        </FormGroup>

        <FormGroup>
          <FormItem>
            <button>Send</button>
          </FormItem>
        </FormGroup>
      </form>
    </>
  )
}

export default ContactForm
