import * as React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Header } from './../../components/Header/Header'

import Home from './../Home/Home'
import About from './../About/About'
import Partners from '../Partners/Partners'
import Contact from './../Contact/Contact'
import { Footer } from '../../components/Footer/Footer'

class App extends React.PureComponent<any, any> {
  public render(): React.ReactChild {
    return (
      <React.Suspense fallback={<div>Loading....</div>}>
        <Router>
          <Header />
          <div style={{ flexGrow: 1, paddingTop: 120 }}>
            <Switch>
              <Route path="/" exact render={() => <Redirect to="/home" />} />
              <Route path="/home" exact component={Home} />
              <Route path="/about" exact component={About} />
              <Route path="/partners" exact component={Partners} />
              <Route path="/contact" exact component={Contact} />
            </Switch>
          </div>
          <Footer />
        </Router>
      </React.Suspense>
    )
  }
}

export default App
