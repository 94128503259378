import * as React from 'react'
import { FullWidthContent, Content, ContentLeft, ContentRight } from '../../components/Content/Content'
import { GradientTitle } from '../../components/GradientTitle/GradientTitle'
import ContactDetails from '../../components/ContactDetails/ContactDetails'
import ContactForm from '../../components/ContactForm/ContactForm'

class Contact extends React.PureComponent<any, any> {
  public render() {
    return (
      <>
        <FullWidthContent backgroundColor="#fff">
          <Content>
            <ContentLeft>
              <GradientTitle>Contact</GradientTitle>
            </ContentLeft>
          </Content>
          <Content between>
            <ContentLeft column>
              <p>
                Do you have any questions or interest?
                <br />
                Use the contact form below to get in contact with us.
              </p>
              <ContactForm />
            </ContentLeft>
            <ContentRight column>
              <ContactDetails />
            </ContentRight>
          </Content>
        </FullWidthContent>
      </>
    )
  }
}

export default Contact
