import styled from 'styled-components'

export const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  margin-top: -120px;
  background: linear-gradient(90deg, #43cea2, #2a8b9f);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HeroTextContainer = styled.div`
  width: 100%;
  min-height: 50%;
  max-height: 70%;
  padding: 60px 0;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 150px;

  & h1 {
    font-size: 50px;
  }

  & p {
    color: #333;
    font-size: 24px;
  }
`

export const HeroImgContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  img.image-left {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }

  img.image-right {
    height: auto;
    width: 70%;
  }
`
