import * as React from 'react'
import { FullWidthContent, Content, ContentLeft, ContentRight } from '../../components/Content/Content'
import { GradientTitle } from '../../components/GradientTitle/GradientTitle'

import Permachine from './../../assets/img/Permachine.jpg'
import sponser1 from './../../assets/img/eceplast-sponser.png'
import sponser2 from './../../assets/img/trust-sponser.png'

class Partners extends React.PureComponent<any, any> {
  public render() {
    return (
      <>
        <FullWidthContent backgroundColor="#fff">
          <Content>
            <ContentLeft>
              <GradientTitle>Partners</GradientTitle>
            </ContentLeft>
          </Content>
          <Content between>
            <ContentLeft column>
              <p>
                Ecocircle has joined forces with two European Companies; Eceplast and Trust. Eceplast is a dry-bulk
                manufacturer and Trust is a Liquid liner manufacture.
              </p>
              <p>
                Both Eceplast and Trust have taken up their responsibility to find an ecological solution for the
                disposal and recycling their single-use liners.
              </p>
              <p>
                This co-operation is unique amongst manufacturers of liners and possibly also in other parts of the
                plastics industry. Ecocircle and APV have coordinated their efforts to refine a total solution for the
                disposal and recycling of liners- from collection to revalorisation as new products.
              </p>
            </ContentLeft>
            <ContentRight end>
              <img src={Permachine} width="300" />
            </ContentRight>
          </Content>
        </FullWidthContent>
        <FullWidthContent backgroundColor="#fff">
          <Content between>
            <ContentLeft>
              <img src={sponser1} style={{ objectFit: 'contain' }} />
            </ContentLeft>
            <ContentRight end>
              <img src={sponser2} style={{ objectFit: 'contain' }} />
            </ContentRight>
          </Content>
        </FullWidthContent>
      </>
    )
  }
}

export default Partners
