import * as React from 'react'
import { HeroContainer, HeroTextContainer, HeroImgContainer } from './styled'
import logoFull from './../../assets/img/ecocircle-logo-full.png'
import img from './../../assets/img/hero-2.png'
import { Content, ContentLeft, ContentRight } from '../Content/Content'

interface Props {
  title?: string
  slogan?: string
}

export const Hero = ({ title, slogan }: Props): React.ReactElement => {
  return (
    <HeroContainer>
      <Content>
        <ContentLeft column center>
          <div>
            <img style={{ marginBottom: 0 }} src={logoFull} />
            <span style={{ fontSize: 36, color: '#fff' }}>Recycling, Import-Export, Plastics</span>
          </div>
        </ContentLeft>
        <ContentRight end vCenter>
          <img className="image-right" height="400" src={img} />
        </ContentRight>
      </Content>
    </HeroContainer>
  )
}
