import * as React from 'react'
import { ContentContainer, ContentSide, FullWidthContainer } from './styled'

export const FullWidthContent = ({ children, ...rest }: any): React.ReactElement => {
  return <FullWidthContainer {...rest}>{children}</FullWidthContainer>
}

export const Content = ({ children, ...rest }: any): React.ReactElement => {
  return <ContentContainer {...rest}>{children}</ContentContainer>
}

export const ContentLeft = ({ children, ...rest }: any): React.ReactElement => {
  return <ContentSide {...rest}>{children}</ContentSide>
}

export const ContentRight = ({ children, ...rest }: any): React.ReactElement => {
  return <ContentSide {...rest}>{children}</ContentSide>
}
